@charset "utf-8";

@import 'frameworks/util/util';
@import 'frameworks/util/mixins';

$global-font-size: 62.5%;

$breakpoints: (
  small: 0,
  medium: 768px,
  // large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);

$grid-padding-gutters: (
  small: 10px,
  medium: 60px,
);

$grid-margin-gutters: (
  small: 10px,
  medium: 30px,
);

// $global-font-size: 62.5%;
$global-width: rem-calc(1046);

@import 'frameworks/foundation';


@include foundation-xy-grid-classes(
  $base-grid: true,
  $margin-grid: true,
  $padding-grid: false,
  $block-grid: true,
  $collapse: false,
  $offset: false,
  $vertical-grid: false,
  $frame-grid: false
);

.grid-x.reverse {
    flex-direction: row-reverse;
}